@mixin minWidth($size) {
    .min-w{
        &-#{$size}{
            min-width: $size+px;
        }
    }
}

@include minWidth(300);

@mixin maxWidth($size) {
    .max-w{
        &-#{$size}{
            max-width: $size+px;
        }
    }
}
@include maxWidth(100);


@mixin minHeight($size) {
    .min-h{
        @if $size == 100vh{
            &-#{$size}{
                min-height: $size;
            }
        }
        @else{
            &-#{$size}{
                min-height: $size+px;
            }
        }
    }
}

@include minHeight(100vh);


@mixin height($val) {
    .h{
        &-#{$val}{
            height: $val+px !important;   
        }
   } 
}

@include height(40);



.uppercase{
    text-transform: uppercase !important;
}
.break-word{
    word-break: break-all;
}
.bold{
    font-weight: bold !important;
}
.text-left{
    text-align: left !important;
}
.ai-end{
    align-items: flex-end;
}
.flex-1{
    flex: 1;
}
.w-100{
    width: 100% !important;
}
.d-b{
    display: block !important;
}
.no-radius{
    border-radius: 0 !important;
}

.zi{
    &-2{
        z-index: 2;
    }
}

.hide{
    &-sm{

    }
    &-lg{
        @media (min-width:992px) {
            display: none !important;
        }
    }
    &-xl{
        @media (min-width:1200px) {
            display: none !important;
        }
    }
}
.show{
    &-lg{
        @media (max-width:991px) {
            display: none !important;
        }
    }
    &-xl{
        @media (max-width:1199px) {
            display: none !important;
        }
    }
}

.shrink-0 {
  flex-shrink: 0;
}