.inputText {
  flex:1;
  font-size: 16px;
  overflow: hidden;
  background-color: #81BF3F !important;
  border: 1px solid #fff;
  font-family: 'Arial';
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #fff !important;
  font-weight: bold;
  height: 100px;
}

.inputText::placeholder {
  font-size: 12px;
  color: #fff !important;
}

.inputMessage{
  height: 40px;
  flex: 1;
  border-radius:5px;
}