.MessageInputContainer{
  
  background:#009fe3;
  /* height:50px; */
  display:flex;
  align-items: center;
  padding: 10px 15px;
  z-index: 2;
}

.MessageInputContainer input{
  flex:1;
}

.MessageInputContainer img{
  width:30px;
  height:30px;
}

.FileSystem{
  display: none;
}

.MessageInputContainer button{
  display:flex;
  width:40px;
  height:40px;
  align-content: center;
  justify-content: center;
  margin:4px;
  background-color: #2FA0D9 !important;
  border:none;
  cursor: pointer;
}

.MessageInputContainer img{
  width:40px;
  height:40px;
  object-fit: contain;
}

.MessageInputContainer button:active{
  border:none;
  background: #009fe3;
  border-radius: 10px;
}

.MessageInputContainer button:focus{
  outline:0;
} 
.MessageInputField {
  flex:1;
  font-size: 16px;
  resize: none;
  outline:none;
  overflow: hidden;
  background-color: #81BF3F;
  border: 1px solid #fff;
  font-family: 'Arial';
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
}
.AttachButton {
	color: #FBB829 !important;
	font-size: 36px;
}
.SendButton {
	color: #FBB829 !important;
	font-size: 36px;
}