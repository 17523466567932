.NewTaskContainer{
  padding: 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 99;
  /* justify-content: center; */
}

.NTClose{
  position: absolute;
  right: 13px;
  top: 13px;
  color: #fff;
}

.FileSystem{
  display: none;
}

.NewTaskBlock{
  background-color: #000;
  width: 100%;
  min-width: 300px;
  max-width: 360px;
  padding: 30px 10px;
  border-radius: 5px;
  position: relative;
  display:flex;
  flex-direction: column;
  color:white;
  @media (min-width:576px) {
    padding: 40px 30px;
  }
}

.NewTaskTitleContainer{
  display:flex;
  align-items: center;
}

.NewTaskTitle{
  font-weight: bold;
  text-align: center;
  flex:1;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.NewTaskInputContainer{
  display:flex;
  align-items: flex-end;
}


.NewTaskContainer img{
  object-fit: contain;
}

.NewTaskContainer button:active{
  border:none;
  background: #009fe3;
  border-radius: 10px;
}

.NewTaskContainer button:focus{
  outline:0;
} 

.InputWrapper{
  display:flex;
  flex:1;
  margin:0px 10px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #81BF3F;
  padding: 2px;
}

.NewTaskInput{
  flex:1;
  font-size: 16px;
  resize: none;
  outline:none;
  overflow: hidden;
  background-color: #81BF3F;
  border: 1px solid #fff;
  font-family: 'Arial';
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: #fff;
  font-weight: bold;
}

.NewTaskInput::placeholder {
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.NewTaskMediaAttachmentList {
  display: flex;
  padding: 10px;
  overflow-y: auto;
  align-items: center;
  border-radius: 6px;
  margin-top: 20px;
  max-height: 250px;

  .NewTaskMediaAttachmentContainer {
    max-height: inherit;
    display: flex;
    flex-wrap: wrap;
    padding-right: 12px;

    .NewTaskMediaAttachmentListItem {
      max-height: 204px;
      overflow: hidden;
      background: #2e2e2e;
      align-self: flex-start;
      padding: 2px;
      background-color: inherit;

      &:not(:only-child) {
        flex: 0 50%;
      }

      img {
        max-width: 100%;
        max-height: 200px;
        border: 2px solid white;
        border-radius: 6px;
      }
    }
  }

  button {
    margin-left: auto;
  }
}

.NewTaskHeader {
	color: #fff;
}

.NewTaskDescription{
	color: #fff;
	font-size: 12px;
}

.NewTaskFileButton, .NewTaskSubmit {
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #FBB829;
	border: none;
	border-radius: 5px;
	font-weight: bold;
	color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}
