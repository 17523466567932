.SignInForm {
  text-align: center;
  display:flex;
  flex-direction: column;
  width: max-content;
}

.ForgotPasswordButton{
  flex:1;
  margin-top: 0px;
  text-align: right;
  background:none;
  border:none;
  color:white;
  font-weight: bold;
}

.ForgotPasswordButton:active{
  border: none;
  outline: none;
}

.ForgotPasswordButton:focus{
  border: none;
  outline: none;
}

.SignInLogo{
  align-self: center;
}

.SignInSpacerSmall{
  height:4px;
}

.SignInSpacer{
  height:20px;
}
.white-background {
	background-color: #fff;
}

.PoweredBy {
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	background-color: #fff !important;
}