.MMenu_P {
  font-size: 20px;
  margin-top: 5px;
  padding-top: 5px;
}

.Hey_Title {
  font-family: 'Arial', cursive;
  font-size: 40px;
  line-height: 60px;
  color: #fdc009;
}

.Hey_Copy {
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  font-weight: 200;
  line-height: 36px;
}

.MMenu_Hey {
  margin-top: 20px;
}

.MenuLink {
  font-size: 10px;
  color: #fff;
  text-decoration: none;
}

.MenuLinkBig {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  border: 1px solid #fff;
  padding: 10px;
}
