.ContentOverlayPage {
  background-color: rgba(0, 0, 0, 0.85);
  position: absolute;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  z-index: 12;
}

.ContentOverlayContainer{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  border-color: white;
  border: 1px solid #f3f3f3; /* Light grey */
  display: table;
  border-radius: 10px;
  margin: 0px auto;
  overflow: hidden;
  padding: 20px;
}

.ContentOverlayTitle{
  text-align: center;
  font-weight: bold;
  margin: 20px;
  font-size: 18px;
  line-height: 22px;
}

.ContentOverlayText {
  white-space: pre;
  margin:20px;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
}

.ContentOverlayButtonContainer {
  text-align: center;
  overflow: hidden;
}

.buttonContainer {
  display: inline-block;
  width: 50%;
  padding: 0px 5px;

  .ContentOverlayButton {
    padding: 0;
    height: 50px;
    border: none;
    border: 1px solid white;
    border-radius: 8px;
    background: none;
    width: 100%;
    color: white;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}

.ContentOverlayButtonAccept{
  padding: 0;
  height:50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  background: none;
  width:50%;
  color:white;
  font-weight: bold;
}

.ContentOverlayButtonAcceptSingle{
  padding: 0;
  height:50px;
  border: none;
  border: 1px solid white;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background: none;
  width:100%;
  color:white;
  font-weight: bold;
}

.mt-1-rem {
  margin-top: 1rem;
}

.mt-2-rem {
  margin-top: 2rem;
}

.mb-1-rem {
  margin-bottom: 1rem;
}

.mb-2-rem {
  margin-bottom: 2rem;
}

.pay-now {
  height: 2em;
  width: 100%;
}

.pay-now-cancel {
  background-color:#424242 !important;
  height: 2em;
  width: 100%;
  color: #FFFFFF !important;
}

.bold-text {
  font-weight: bold;
}

.pay-now-hide{
  display:none;
}

.mr-1-rem {
  margin-right: 1rem;
}

.ml-1-rem {
  margin-left: 1rem;
}