.picture {
    width: auto;
    height: auto;
    max-width: 100%;
    object-fit: cover;
}
.logo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.user {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    @media (min-width:768px) {
        width: 50px;
        height: 50px;
    }
}