$default_yellow: #FFCC01;
$default_white: #FFFFFF;
$default_black: #000;
$default_gray: #F15026;
$default_blue_bahama: #FBB72D;
$default_biscay: #FBB72D;
$default_malibu: #20c997;

@mixin color($name, $val) {
    &-#{$name}{
        color: $val !important;
    }
}

.color{
    @include color('white', $default_white);
    @include color('black', $default_black);
    @include color('default-gray', $default_gray);
}
